import React, {useEffect} from "react";
import {Provider} from 'react-redux';
import {initStore} from '../store';
import sentry from '../lib/utilities/sentry';
import TagManager from "react-gtm-module";
import {hotjar} from "react-hotjar";

/*
 * Insert CSS globally
 */

import "../styles/index.scss";
import "../styles/tailwind.css";
import {getCheckout} from "../lib/actions/checkout";

const tagManagerArgs = {
  gtmId: process.env.GTM_ID,
};

const {captureException} = sentry();
const store = initStore();

function DuluxApp({Component, pageProps}) {
  // On load
  useEffect(() => {
    store.dispatch(getCheckout());
    TagManager.initialize(tagManagerArgs);
    hotjar.initialize(process.env.HOTJAR.id, process.env.HOTJAR.sv);
  }, []);

  return (
    <Provider store={store}>
      <Component {...pageProps} />
    </Provider>
  );
};

export default DuluxApp;
